import { useState } from 'react';
import ForgotPasswordPageModel from '../../ForgotPasswordPage/Models/ForgotPasswordPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import Input from '../../Atoms/Input/Input';
import { InputValidation } from '../../Atoms/Input/InputValidation';
import { ExclamationMarkIcon, LoadingCircle } from '../../Atoms/Icons';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import Divider from '../../Atoms/Divider/Divider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { H1, H2 } from '../../Atoms/Typography/Headings/Heading';
import Button from '../../Atoms/Buttons/Button';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import UserState from '../../Shared/Models/UserState.interface';
import { ForgotPassword, CheckForgotPassword } from './Account';
import UserCredentials from '../../LoginPage/Models/UserCredentials.interface';
import { getUrlParameter } from '../../Shared/Common/Helpers';
import { useEffect } from 'react';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import ContentContainer from '../../Molecules/ContentContainer/ContentContainer';
import Dots from '../../Atoms/Loaders/Dots';

function ForgotPasswordPage() {
  const kexNavigate = useKexNavigate();
  const { pageHeading, pageIntroText, forgotPasswordText, channelId, pageId } =
    useCurrentPage<ForgotPasswordPageModel>();
  const [user] = useState<UserState>(useUserStateData());
  const [username, setUsername] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');

  const {
    'account/email': emaiLabel,
    'account/forgotPassword': forgotPassword,
    'login/magicLinkSend': sendLink,
  } = useTranslationData();

  const { languageRoute, staticPages } = useAppSettingsData();

  const userCredentials: UserCredentials = {
    userName: username,
    language: languageRoute,
  };
  const disabled = username.length === 0;
  var forgotPasswordLink = getUrlParameter('link');

  useEffect(() => {
    if (forgotPasswordLink) {
      CheckForgotPassword(
        languageRoute,
        forgotPasswordLink,
        channelId,
        staticPages.createNewPasswordPage,
        setError,
        setValidationMessage,
        setIsChecking
      );
    }
  }, [forgotPasswordLink]);

  const sendForgotPassword = () => {
    ForgotPassword(
      userCredentials,
      staticPages.forgotPasswordPage,
      channelId,
      pageId,
      setSuccess,
      setError,
      setValidationMessage,
      setIsLoading
    );
  };

  return (
    <main>
      {!isChecking ? (
        <Container>
          <InfoGridBox>
            <StyledH1 noMargin={true}>{pageHeading}</StyledH1>
            <IntroText>{pageIntroText}</IntroText>
          </InfoGridBox>
          <FormGridBox>
            <FormContainer>
              <StyledH2 noMargin={true}>{forgotPassword}</StyledH2>
              <FromDetailText>{forgotPasswordText}</FromDetailText>
              {(error || success) && (
                <ValidationMessage success={success}>
                  <ExclamationMarkIcon
                    color={success ? 'white' : 'primary'}
                    css={IconStyleError}
                  />
                  <ErrorMessage>{validationMessage}</ErrorMessage>
                </ValidationMessage>
              )}
              <InputValidation>
                <Input
                  title={emaiLabel}
                  onChange={setUsername}
                  onKeyDown={(e) => e.key === 'Enter' && sendForgotPassword()}
                />
              </InputValidation>
              <ButtonContainer>
                <ForgotPasswordButton
                  onClick={sendForgotPassword}
                  type={'secondary'}
                  disabled={disabled}
                  isLoading={isLoading}
                >
                  {sendLink}
                </ForgotPasswordButton>
              </ButtonContainer>
            </FormContainer>
          </FormGridBox>
        </Container>
      ) : (
        <ContentContainer>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ContentContainer>
      )}
    </main>
  );
}

export default ForgotPasswordPage;

const IconStyleError = {
  wh: 4,
  mr: 2,
  mt: 1,
};

const Container = styled('div', {
  display: 'grid',
  gg: 4,
  gridTemplateColumns: '1fr',
  wh: '100%',
  justifyContent: 'center',
  grg: 0,
  mx: 'auto',
  '@mediaMinLarge': {
    gridTemplateColumns: '2fr 3.5fr',
    gg: 0,
  },
  maxW: '$contentMaxWidth',
});

const GridBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  p: 8,
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  w: '100%',
  py: 16,
});

const InfoGridBox = styled(GridBox, {});

const FormGridBox = styled(GridBox, {});

const StyledH1 = styled(H1, {});

const StyledH2 = styled(H2, {
  pb: 3,
});

const IntroText = styled('p', {
  fontSize: '14px',
  lineHeight: '20px',
  ls: '1px',
});

const FormContainer = styled('div', {
  fs: 13,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls125',
  pb: 16,
  '@mediaMinGreat': {
    px: 47,
    py: 6,
  },
});

const FromDetailText = styled('p', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  mb: 6,
  mt: 0,
});

const ValidationMessage = styled('div', {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorBackground',
  color: '$errorText',
  p: 6,
  my: 6,
  display: 'flex',
  variants: {
    success: {
      true: {
        backgroundColor: '$successBackground',
        color: '$successText',
      },
    },
  },
});

const ErrorMessage = styled('p', {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  m: 0,
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  mt: 8,
});

const ForgotPasswordButton = styled(CtaButton, {});
